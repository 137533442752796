(function () {
  'use strict';

  /* @ngdoc object
   * @name conducaoEconomica
   * @description
   *
   */
  angular
    .module('conducaoEconomica', [
      'ngCookies',
      'ui.router',
      'ui.bootstrap',
      'blockUI',
      'flash',
      'angular-growl',
      'ngAnimate',
      'ui.select',
      'ngSanitize',
      'angularMoment',
      'daterangepicker',
      'smart-table',
      'ngImgCrop',
      'restangular',
      'enumModule',
      'v3gWebComponents',
      'newsModule',
      'ngStomp',
      'home',
      'relatorios',
      'mapas',
      'mapasGrid',
      'ui.slimscroll',
      'historicoTimeline',
      'angulartics',
      'angulartics.google.analytics',
      'indicadores',
      'cadastros',
      'ui.toggle',
      'configEventos',
      'configVdrivers',
      'historicoAlerta',
      'alert-message',
      'xeditable',
      'ui.utils.masks',
      'ModuleMapasUtil',
      'ModuleConvertersUtil',
      'ModuleEventos',
      'mapControlModule',
      'resumoPeriodoModule',
      'mapaCalor',
      'onePageModule',
      'ranking.Infracoes',
      'ranking.Pontos',
      'CentralNotificacoesModule',
      'ObjetosVfiltroModule',
      'VfiltroModule',
      'VtabelaModule',
      'VTabelaCellFilter',
      'ProntuarioModule',
      'ngMessages',
      'reprodRotasModule',
      'idf.br-filters',
      'quadroOcorrencias',
      'pascalprecht.translate',
      'FeatureFlagModule',
      'urlConfigModule',
      'httpServiceV3gModule',
      'v3gCustomPopupModule',
      'uoSelectInputModule',
      'vUiSelectModule',
      'informativoModule',
      'planosSolucoesModule',

      'middlewareModule',
      'v3gServicosModule',
      'v3gLinhasModule',
      'v3gVeiculoModule',
      'motoristaModule',
      'documentoModule',
      'filtroAlertaModule',
      'diagnosticoModule',
      'sidebarMenuToggleModule',
      'uoModule',
      'uoArvoreNivelModule',
      'linkOnboardingModule',
      'v3gValidatorModule',
      'modalInfoConfirmModule',
      'v3gTrimbleFilters',
      'v3gAuthenticatorModule',
      'ConstantModule',
      'v3gHeaderModule',
      'AlertasModule',
      'SolucoesModule',
      'usuarioModule',
      'headerModule',
      'stateUrlModule',
      'vflashModule',
      'insightsModule',
      'planosSolucoesNovoUsuarioModule',
      'errorPageModule',
      'ModuleMeasurementUnitConverter',
      'FeatureOuModule',
      'safetyModule',
      'ProntuarioV2Module'
    ])
    .run(configureRestAngular)
    .run(['$rootScope', '$state', '$interval', 'editableOptions', '$uibModalStack', '$timeout', 'StateUrlService',
    'Authenticator', '$translate',
    ($rootScope, $state, $interval, editableOptions, $uibModalStack, $timeout, StateUrlService, Authenticator, $translate) => {
      Authenticator.getUser().then(user => {
        $translate.use(user.idioma.toLowerCase());
        $translate.fallbackLanguage('pt_br');
      });
      $rootScope.$state = $state;

      StateUrlService.initInterceptInformativo();

      function paraTimerDoMapaQuandoMudarARota() {
        if (angular.isDefined($rootScope.timerMapa)) {
          $interval.cancel($rootScope.timerMapa);
          $rootScope.timerMapa = undefined;
        }
      }

      let stateChangeStart = $rootScope.$on('$stateChangeStart', (event, to, toParams, from) => {
            $state.previous = from;
            paraTimerDoMapaQuandoMudarARota();
          }),
          locationChangeStart = $rootScope.$on('$locationChangeStart', () => $uibModalStack.dismissAll()),
          locationChangeSuccess = $rootScope.$on('$stateChangeSuccess', () => {
            StateUrlService.onPageLoad();
            StateUrlService.onProductValidationSnackbarLoad();
          });

      editableOptions.theme = 'bs3';

      $rootScope.$on('$destroy', locationChangeStart);
      $rootScope.$on('$destroy', stateChangeStart);
      $rootScope.$on('$destroy', locationChangeSuccess);

      StateUrlService.onPageChange();
    }])
    .config(tooltipConfig)
    .config(configI18n)
    .config(configMomentRelativeTime);

  function configureRestAngular(Restangular, UrlConfigService, Authenticator) {
    Restangular.setBaseUrl(UrlConfigService.getApiGatewayUrl());
    Restangular.setDefaultHttpFields({
      withCredentials: true
    });
    Restangular.setFullRequestInterceptor((element, operation, route, url, headers, params, httpConfig) => {
      headers.Authorization = `Bearer ${Authenticator.keycloak.token}`;
      return {
        element: element,
        params: params,
        headers: headers,
        httpConfig: httpConfig
      };
    });
  }

  function tooltipConfig($uibTooltipProvider) {
    $uibTooltipProvider.options({appendToBody: true});
  }

  function configI18n($translateProvider, I18N_VERSION) {
    $translateProvider.useStaticFilesLoader({
      prefix: `https://vfleets-i18n.s3.sa-east-1.amazonaws.com/${I18N_VERSION}/`,
      suffix: '.json'
    });
    $translateProvider.useSanitizeValueStrategy('sceParameters');
    $translateProvider.forceAsyncReload(true);
  }

  function configMomentRelativeTime(moment) {
    moment.updateLocale('pt-br', {
      relativeTime: {
        past: 'há %s',
        w: 'uma semana',
        ww: '%d semanas',
        ss: '%d segundos'
      }
    });
    /* eslint-disable no-inline-comments */
    moment.relativeTimeThreshold('s', 60); // seconds to minute
    moment.relativeTimeThreshold('m', 60); // minutes to hour
    moment.relativeTimeThreshold('h', 24); // hours do day
    moment.relativeTimeThreshold('d', 7); // days to week
    moment.relativeTimeThreshold('w', 4); // weeks to month
    moment.relativeTimeThreshold('M', 12); // months to year
    /* eslint-enable no-inline-comments */
  }
}());
