/* global document */
(function () {
  'use strict';

  const HORA_EM_SEGUNDOS = 3600,
      KM_EM_METROS = 1000;

  /* global _ */
  class VeiculoCtrl {
    constructor(Restangular, moment, $uibModal, $filter, AlertMessage, CadastroVeiculoService, HttpServiceV3g,
                Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, VtabelaFactory, TipoPermissaoEnum,
                ModalInfoConfirmService, $q, $scope, $translate, FeatureFlag, $state, $window, Authenticator, MeasurementUnitConverterService, FeatureOuService) {
      this.httpService = HttpServiceV3g;
      this.veiculoService = CadastroVeiculoService;
      this.authenticator = Authenticator;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.FeatureOuService = FeatureOuService;
      this.restangular = Restangular;
      this.moment = moment;
      this.modalService = $uibModal;
      this.featureFlag = FeatureFlag;
      this.filter = $filter;
      this.alertMessage = AlertMessage;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.vtabelaFactory = VtabelaFactory;
      this.tipoPermissaoEnum = TipoPermissaoEnum;
      this.modalInfoConfirmService = ModalInfoConfirmService;
      this.q = $q;
      this.scope = $scope;
      this.translate = $translate;
      this.semPermissaoEditar = this.translate.instant('ce.cadastro.veiculos.colunas.semPermissaoEditar');
      this.cliqueEditar = this.translate.instant('ce.cadastro.veiculos.colunas.cliqueEditar');
      this.tooltipIntervaloManutencao = this.translate.instant('ce.cadastro.veiculos.colunas.intervaloManutencao.headerTooltip');
      this.sucessoPt1 = this.translate.instant('ce.cadastro.veiculos.modalManutencao.sucessoPt1');
      this.cadastrada = this.translate.instant('ce.cadastro.veiculos.modalManutencao.cadastrada');
      this.removida = this.translate.instant('ce.cadastro.veiculos.modalManutencao.removida');
      this.sucessoPt2 = this.translate.instant('ce.cadastro.veiculos.modalManutencao.sucessoPt2');
      this.tooltipDisabledMenu = this.translate.instant('ce.cadastro.veiculos.colunas.conducao.disableTooltip');
      this.state = $state;
      this.window = $window;
      this.veiculos = [];
      this.vtabela = null;
      this.relatorio = null;
      this.currentPage = 0;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.relatorioSafe = null;
      this.isFirstLoad = true;
      this.hasSensor = false;

      this.beforeEdit = null;

      this.mapStatusI18N = {
        'EM TESTE': this.translate.instant('ce.enum.statusVeiculo.emTeste'),
        INATIVO: this.translate.instant('ce.enum.statusVeiculo.inativo'),
        'INSTALACAO PENDENTE': this.translate.instant('ce.enum.statusVeiculo.instalacaoPendente'),
        LIBERADO: this.translate.instant('ce.enum.statusVeiculo.liberado'),
        REMOVIDO: this.translate.instant('ce.enum.statusVeiculo.removido'),
        SUSPENSO: this.translate.instant('ce.enum.statusVeiculo.suspenso')
      };

      this.mapSituacaoI18N = {
        'Fora de Operação': this.translate.instant('ce.enum.situacaoVeiculo.foraOperacao'),
        Sinistrado: this.translate.instant('ce.enum.situacaoVeiculo.sinistrado'),
        'Em manutenção': this.translate.instant('ce.enum.situacaoVeiculo.emManutencao'),
        'Em operação': this.translate.instant('ce.enum.situacaoVeiculo.emOperacao'),
        'Parado no pátio': this.translate.instant('ce.enum.situacaoVeiculo.paradoPatio'),
        Reserva: this.translate.instant('ce.enum.situacaoVeiculo.reserva'),
        'Manutenção mecânica': this.translate.instant('ce.enum.situacaoVeiculo.manutencaoMecanica'),
        'Processo de cancelamento': this.translate.instant('ce.enum.situacaoVeiculo.processoCancelamento'),
        'Processo de troca por outro veículo': this.translate.instant('ce.enum.situacaoVeiculo.processoTrocaOutroVeiulo'),
        'Processo de Transferência de operador Logístico': this.translate.instant('ce.enum.situacaoVeiculo.processoTransferenciaOperador'),
        'Em rota': this.translate.instant('ce.enum.situacaoVeiculo.emRota'),
        'Em processo de cancelamento': this.translate.instant('ce.enum.situacaoVeiculo.emProcessoCancelamento')
      };

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        },
        {
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          key: this.keys.veiculo.name,
          size: 6
        }
      ]))
      .then(() => {
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VEICULO_EDITAR');
        this.roleVigenciaProgramadaVisualizar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VIGENCIA_PROGRAMADA_VISUALIZAR');
        this.restangular.one(`configuracao/configuracoes/${this.serviceVfiltro.factoryVfiltro.user.uo.id}/alerta`).get()
        .then(alertas => {
          this.permissaoManutencaoProgramada = alertas.configuracoes.manutencaoProgramada.ativo;
        });
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'cadastroVeiculo', 'v1');
        this.consultar();
      });

      this.initUserConfig();
      this.scope.$on('vehicleRegistered', () => {
        this.reloadVehicleTable();
      });
    }

    initUserConfig() {
      this.authenticator.getUser().then(user => {
        this.uoId = user ? user.uo.id : null;
        this.userPerfilId = user ? user.perfil.id : null;
        this.user = user;
        this.isAdminVeltec = user.perfil.id === 1;
        this.dateFormat = user.measurementUnits.dateTimeFormat;
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormat(this.dateFormat);
        this.FeatureOuService.getVehicleTableConfig(user.uo.id).then(isTTCUser => {
          this.isTTCUser = isTTCUser;
        });
      });
    }

    getMaisOpcoesMsg() {
      if (!this.vtabela || !this.vtabela.rowsSelected || this.vtabela.rowsSelected.length === 0) {
        return this.translate.instant('ce.cadastro.veiculos.menuOpcoes.msg');
      }
      if (this.getEditableRowsSelected().length === 0) {
        return this.semPermissaoEditar;
      }
      return null;
    }

    onSaveRowVtabela(rowEntity) {
      let promise = null;
      if (rowEntity.hodometro !== this.beforeEdit.hodometro) {
        promise = this.confirmarAlteracaoHodometro(rowEntity.hodometro, this.beforeEdit.hodometro, rowEntity);
      } else if (rowEntity.prefixo !== this.beforeEdit.prefixo) {
        promise = this.confirmarAlteracaoPrefixo(rowEntity);
      }

      if (promise !== null) {
        this.vtabela.gridApi.rowEdit.setSavePromise(rowEntity, promise);
      }
    }

    initVtabela() {
      let objVtabela = {
        exporterCsvFilename: `${this.translate.instant('ce.cadastro.veiculos.csvFileName')}.csv`,
        appScopeProvider: this,
        enableCellEdit: true,
        rowEditWaitInterval: 1,
        saveRow: (rowEntity) => {
          this.onSaveRowVtabela(rowEntity);
        },
        beginCellEdit: (rowEntity) => {
          this.beforeEdit = angular.copy(rowEntity);
        },
        columnDefs: [
            {
              name: 'placa',
              displayName: 'ce.cadastro.veiculos.colunas.placa',
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false,
              enableCellEdit: false,
              pinnedLeft: true,
              cellTemplate:
              `<div class="ui-grid-cell-contents">
                <i
                  ng-if="!grid.appScope.podeEditarVeiculo(row.entity)"
                  class="fa fa-minus-circle"
                  uib-tooltip='{{ grid.appScope.semPermissaoEditar }}'>
                </i>
                <span ng-class='{"no-icon": grid.appScope.podeEditarVeiculo(row.entity)}'>
                  {{row.entity.placa}}
                </span>
              </div>`
            },
            {
              name: 'prefixo',
              displayName: 'ce.cadastro.veiculos.colunas.prefixo',
              type: 'string',
              filterCellFiltered: true,
              cellTemplate: `
                <div class="editable-double-click ui-grid-cell-contents-container"
                  title="{{ grid.appScope.podeEditarVeiculo(row.entity) ? grid.appScope.cliqueEditar : grid.appScope.semPermissaoEditar }}">
                  <div class='ui-grid-cell-contents'>
                    <span class='span-ui-grid-cell-contents'>
                      {{ row.entity.prefixo }}
                    </span>
                  </div>
                </div>
              `,
              cellEditableCondition: ($scope) => {
                return this.podeEditarVeiculo($scope.row.entity);
              },
              editableCellTemplate: `
              <div class="editable-double-click ui-grid-cell-contents-container">
                <div class='ui-grid-cell-contents'>
                  <form name="edit-prefixo">
                    <input type="text" ui-grid-editor ng-model="row.entity.prefixo" maxlength="20">
                  </form>
                </div>
              </div>`
            },
            {
              name: 'uoNome',
              displayName: 'ce.cadastro.veiculos.colunas.uoNome',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false,
              enableHiding: false
            },
            {
              name: 'vin',
              displayName: 'ce.cadastro.veiculos.colunas.vin',
              cellClass: 'text-left',
              cellFilter: 'withLabelToUndefined:\'-\'',
              type: 'string',
              visible: this.isTTCUser,
              enableHiding: this.isTTCUser
            },
            {
              name: 'grupo',
              displayName: 'ce.cadastro.veiculos.colunas.grupo',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'tipo',
              displayName: 'ce.cadastro.veiculos.colunas.tipo',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'situacao',
              displayName: 'ce.cadastro.veiculos.colunas.situacao',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'dataRetorno',
              displayName: 'ce.cadastro.veiculos.colunas.dataRetorno.colLabel',
              type: 'date',
              cellFilter: 'date:\'dd/MM/yyyy\'',
              filterCellFiltered: true,
              enableCellEdit: false,
              cellClass: 'text-center',
              cellTemplate: `
                <div class='ui-grid-cell-contents' ng-if="row.entity.dataRetorno">
                  <span class="icone-esquerda">{{row.entity.dataRetorno}}</span>
                  <i
                    class="fa fa-wrench text-blue"
                    uib-tooltip="{{ 'ce.cadastro.veiculos.colunas.dataRetorno.iconTooltip' | translate }}">
                  </i>
                </div>
                <div class='ui-grid-cell-contents' ng-if="!row.entity.dataRetorno">-</div>
              `
            },
            {
              name: 'marca',
              displayName: 'ce.cadastro.veiculos.colunas.marca',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'modelo',
              displayName: 'ce.cadastro.veiculos.colunas.modelo',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'ano',
              displayName: 'ce.cadastro.veiculos.colunas.ano',
              cellClass: 'text-right',
              type: 'number',
              filterCellFiltered: true,
              enableCellEdit: false,
              cellFilter: 'withLabelToUndefined:\'-\''
            },
            {
              name: 'hodometro',
              displayName: 'ce.cadastro.veiculos.colunas.hodometro',
              filterCellFiltered: true,
              cellTemplate: `
                <div class="editable-double-click ui-grid-cell-contents-container"
                  title="{{ grid.appScope.podeEditarVeiculo(row.entity) ? grid.appScope.cliqueEditar : grid.appScope.semPermissaoEditar }}">
                  <div class='ui-grid-cell-contents'>
                    <span ng-class='{"no-icon": grid.appScope.podeEditarVeiculo(row.entity)}'>
                      {{row.entity.hodometro | number:1}}
                    </span>
                  </div>
                </div>
              `,
              cellEditableCondition: ($scope) => {
                return this.podeEditarVeiculo($scope.row.entity);
              },
              editableCellTemplate: `
                <div class="editable-double-click ui-grid-cell-contents-container">
                  <div class='ui-grid-cell-contents'>
                    <form name="edit-hodometro">
                      <input type="text" ui-grid-editor ng-model="row.entity.hodometro" ui-hide-group-sep min=0 ui-number-mask=1>
                    </form>
                  </div>
                </div>`
            },
            {
              name: 'status',
              displayName: 'ce.cadastro.veiculos.colunas.status',
              type: 'string',
              filterCellFiltered: true,
              enableCellEdit: false
            },
            {
              name: 'timezone',
              displayName: 'ce.cadastro.veiculos.colunas.timezone',
              cellClass: 'text-left',
              cellFilter: 'withLabelToUndefined:\'-\'',
              type: 'string',
              visible: this.isTTCUser,
              enableHiding: this.isTTCUser
            },
            {
              name: 'intervaloManutencao',
              displayName: 'ce.cadastro.veiculos.colunas.intervaloManutencao.colLabel',
              cellClass: 'text-right',
              headerCellClass: 'icon-info-tooltip',
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerTooltip: this.tooltipIntervaloManutencao,
              type: 'number',
              enableCellEdit: false
            }
        ]
      };

      if (this.hasSensor) {
        this.intervaloManutencaoHorimetro = this.translate.instant('ce.cadastro.veiculos.colunas.intervaloManutencaoHorimetro.colLabel');
        this.tooltipManutencaoHorimetro = this.translate.instant('ce.cadastro.veiculos.colunas.intervaloManutencaoHorimetro.headerTooltip');
        objVtabela.columnDefs.push({
          name: 'intervaloManutencaoHorimetro',
          displayName: this.intervaloManutencaoHorimetro,
          cellClass: 'text-right',
          headerCellClass: 'icon-info-tooltip',
          cellFilter: 'withLabelToUndefined:\'-\'',
          headerTooltip: this.tooltipManutencaoHorimetro,
          type: 'number',
          enableCellEdit: false
        });
      }

      if (this.roleVigenciaProgramadaVisualizar && this.featureFlag.VIGENCIA_PROGRAMADA) {
        objVtabela.columnDefs.push(
          {
            name: 'conducaoInicio',
            displayName: 'ce.cadastro.veiculos.colunas.conducao.colLabelInicio',
            type: 'date',
            cellFilter: 'date:\'dd/MM/yyyy\'',
            filterCellFiltered: true,
            cellClass: 'text-center',
            visible: false,
            cellTemplate: `
              <div class='ui-grid-cell-contents' ng-if="row.entity.conducaoInicio">
                <span uib-tooltip='{{ grid.appScope.getTooltipMsg(row.entity.conducao) }}'>
                  {{ row.entity.conducaoInicio }}
                </span>
              </div>
              <div class='ui-grid-cell-contents' ng-if="!row.entity.conducaoInicio"></div>
            `
          },
          {
            name: 'conducaoFim',
            displayName: 'ce.cadastro.veiculos.colunas.conducao.colLabelFim',
            type: 'date',
            cellFilter: 'date:\'dd/MM/yyyy\'',
            filterCellFiltered: true,
            cellClass: 'text-center',
            visible: false,
            cellTemplate: `
              <div class='ui-grid-cell-contents' ng-if="row.entity.conducaoFim">
                <span uib-tooltip='{{ grid.appScope.getTooltipMsg(row.entity.conducao) }}'>
                  {{ row.entity.conducaoFim }}
                </span>
              </div>
              <div class='ui-grid-cell-contents' ng-if="!row.entity.conducaoFim"></div>
            `
          });
      }

      objVtabela.columnDefs.push(
        {
          name: 'veiculoSync',
          displayName: '',
          enableFiltering: false,
          enableSorting: false,
          pinnedRight: true,
          enableColumnResizing: false,
          enableHiding: false,
          visible: this.isTTCUser,
          headerCellTemplate: `
          <span
            class="fa trimble-cloud-connected info-motorista-sync-icon"
            data-testid="vehicle-sync-icon"
          </span>`,
          exporterSuppressExport: true,
          width: 55,
          cellTemplate: `
          <a ng-if="row.entity.dateSync"
          uib-tooltip="{{ 'ce.cadastro.veiculos.colunas.ultimoUpdateTooltip' | translate: { lastSyncUpdate: row.entity.dateSync } }}"
          tooltip-placement="top-right"
          tooltip-class="custom-tooltip"
          data-testid="vehicle-sync-icon-{{row.entity.placa}}"
          class="fa trimble-cloud-connected icone"></a>`
        });

      return objVtabela;
    }

    getTooltipMsg(conducao) {
      return this.translate.instant(
        'ce.cadastro.veiculos.colunas.conducao.headerTooltip',
         {dataInicio: conducao.inicio, dataFim: conducao.fim}
      );
    }

    podeEditarVeiculo(entity) {
      return this.roleCadastrar && this.tipoPermissaoEnum.podeEditar(entity.tipoPermissao);
    }

    getItemVtabela(item) {
      let latestSyncDateWithTtc = null;
      if (item.ultimoSyncTtc || item.ultimoSyncVfleets) {
        const ultimo_sync_ttc = item.ultimoSyncTtc ? new Date(item.ultimoSyncTtc).getTime() : 0;
        const ultimo_sync_vfleets = item.ultimoSyncVfleets ? new Date(item.ultimoSyncVfleets).getTime() : 0;
        latestSyncDateWithTtc = new Date(Math.max(ultimo_sync_ttc, ultimo_sync_vfleets));
      }
      return {
        id: item.id,
        prefixo: item.prefixo,
        placa: item.placa,
        uo: item.uo,
        uoNome: item.uo ? item.uo.nome : '-',
        uoId: item.uo ? item.uo.id : null,
        grupo: item.grupo && item.grupo.nome ? item.grupo.nome : '-',
        tipo: item.tipo,
        situacao: item.situacaoVeiculo && item.situacaoVeiculo.nome ? this.getSituacao18n(item.situacaoVeiculo.nome) : '-',
        situacaoEnum: item.situacaoVeiculo && item.situacaoVeiculo.nome ? item.situacaoVeiculo.nome : '-',
        marca: item.marca,
        modelo: item.modelo,
        hodometro: item.hodometro,
        horimetro: item.horimetro,
        ano: item.ano,
        status: this.getStatusi18n(item.status),
        statusEnum: item.status,
        timezone: item.timezone,
        vin: item.chassi,
        hodometroProximoAlertaManutencao: this.valueOrNull(item.manutencaoProgramada, 'hodometroProximoAlertaManutencao'),
        intervaloManutencao: this.valueOrNull(item.manutencaoProgramada, 'intervaloManutencao'),
        margemAlertaManutencao: this.valueOrNull(item.manutencaoProgramada, 'margemAlertaManutencao'),
        horimetroProximoAlertaManutencao: this.valueOrNull(item.manutencaoProgramada, 'horimetroProximoAlertaManutencao'),
        intervaloManutencaoHorimetro: this.valueOrNull(item.manutencaoProgramada, 'intervaloManutencaoHorimetro'),
        margemAlertaManutencaoHorimetro: this.valueOrNull(item.manutencaoProgramada, 'margemAlertaManutencaoHorimetro'),
        tipoPermissao: item.tipoPermissao,
        tipoEntidade: item.tipoEntidade,
        dataRetorno: item.dataRetorno ? this.moment(item.dataRetorno).format('DD/MM/YYYY').toString() : item.dataRetorno,
        conducaoInicio: item.vigenciaProgramada ? this.moment(item.vigenciaProgramada.inicio).format('DD/MM/YYYY').toString() : null,
        conducaoFim: item.vigenciaProgramada ? this.moment(item.vigenciaProgramada.fim).format('DD/MM/YYYY').toString() : null,
        conducao: item.vigenciaProgramada,
        dateSync: this.filter('date')(latestSyncDateWithTtc, this.dateTimeFormat)
      };
    }

    valueOrNull(obj, atributo) {
      return obj ? obj[atributo] || null : null;
    }

    vDataToVeiculo(list) {
      if (angular.isArray(list)) {
        return list.map(i => this.itemToVeiculo(i));
      }
      return this.itemToVeiculo(list);
    }

    itemToVeiculo(item) {
      return {
        id: item.id,
        prefixo: item.prefixo,
        placa: item.placa,
        marca: item.marca,
        modelo: item.modelo,
        hodometro: item.hodometro,
        horimetro: item.horimetro,
        ano: item.ano,
        grupo: {
          nome: item.grupo
        },
        situacaoVeiculo: {
          nome: item.situacaoEnum
        },
        tipo: item.tipo,
        status: item.statusEnum,
        timezone: item.timezone,
        vin: item.chassi,
        manutencaoProgramada: {
          hodometroProximoAlertaManutencao: item.hodometroProximoAlertaManutencao,
          intervaloManutencao: item.intervaloManutencao,
          margemAlertaManutencao: item.margemAlertaManutencao,
          horimetroProximoAlertaManutencao: item.horimetroProximoAlertaManutencao,
          intervaloManutencaoHorimetro: item.intervaloManutencaoHorimetro,
          margemAlertaManutencaoHorimetro: item.margemAlertaManutencaoHorimetro
        },
        uo: {
          id: item.uoId,
          nome: item.uoNome
        },
        tipoPermissao: item.tipoPermissao,
        tipoEntidade: item.tipoEntidade,
        dataRetorno: item.dataRetorno ? this.moment(item.dataRetorno).format('DD/MM/YYYY').toString() : item.dataRetorno
      };
    }

    getUrlCsv() {
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          uoParceira = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uoParceira.name);
      return `/veiculo/v2/veiculos/csv?uoPermissao=${uoId}&uoId=${uoParceira ? uoParceira.value.id : ''}`;
    }

    isVeiculoEditavel() {
      return this.vtabela && this.vtabela.rowsSelected.length === 1 &&
        this.tipoPermissaoEnum.podeEditar(this.vtabela.rowsSelected[0].tipoPermissao) &&
        this.roleCadastrar;
    }

    openConfigConducao(veiculos) {
      if (veiculos.length === 1) {
      /* eslint-disable */
        const elementContainer = document.querySelector('#dialogConfigConducao'),
              dialogConfigConducao = document.createElement('vfwc-dialog-conducao-programada');
        /* eslint-enable */

        dialogConfigConducao.user = this.serviceVfiltro.factoryVfiltro.user;
        dialogConfigConducao.veiculo = veiculos[0];
        elementContainer.appendChild(dialogConfigConducao);
        this.onConfigSalva(dialogConfigConducao);
      }
    }

    onConfigSalva(element) {
      element.addEventListener('successConducaoProgramada', () => {
        this.reloadVehicleTable();
      });
    }

    reloadVehicleTable() {
      this.consultar();
      this.vtabela.rowsSelected = [];
    }

    isVeiculoEditavelComIntervaloDeManutencao() {
      return this.isVeiculoEditavel() && this.vtabela.rowsSelected[0].intervaloManutencao;
    }

    isVeiculoEditavelComIntervaloDeManutencaoHorimetro() {
      return this.isVeiculoEditavel() && this.vtabela.rowsSelected[0].intervaloManutencaoHorimetro;
    }

    consultar() {
      const uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          uoParceira = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uoParceira.name),
          filtroVeiculo = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.veiculo.name),
          veiculoId = filtroVeiculo && filtroVeiculo.value && filtroVeiculo.value.id;

      this.q.all([
        this.veiculoService.getByUo(uoId, uoParceira ? uoParceira.value.id : '', veiculoId),
        this.veiculoService.hasSensor(uoId)
      ]).then(([resVeiculos, resHasSensor]) => {
        this.veiculos = resVeiculos.plain().content;
        this.hasSensor = resHasSensor;
        this.carregarRelatorio();
      });
    }

    carregarRelatorio() {
      this.relatorio = this.filter('orderBy')(this.isFirstLoad ?
        this.veiculos.map(v => {
          v.hodometro = angular.isDefined(v.hodometro) ? parseFloat((v.hodometro / KM_EM_METROS).toFixed(1)) : 0;
          v.horimetro = angular.isDefined(v.horimetro) ? parseFloat((v.horimetro / HORA_EM_SEGUNDOS).toFixed(1)) : 0;
          if (v.manutencaoProgramada) {
            v.manutencaoProgramada.intervaloManutencao /= KM_EM_METROS;
            v.manutencaoProgramada.margemAlertaManutencao = v.manutencaoProgramada.margemAlertaManutencao ?
              v.manutencaoProgramada.margemAlertaManutencao / KM_EM_METROS :
              null;
            v.manutencaoProgramada.intervaloManutencaoHorimetro /= HORA_EM_SEGUNDOS;
            v.manutencaoProgramada.margemAlertaManutencaoHorimetro = v.manutencaoProgramada.margemAlertaManutencaoHorimetro ?
              v.manutencaoProgramada.margemAlertaManutencaoHorimetro / HORA_EM_SEGUNDOS :
              null;
          }
          return v;
        })
        : this.veiculos, 'placa');

      this.relatorioSafe = [].concat(this.relatorio);
      this.vtabela.setData(this.relatorioSafe.map(i => this.getItemVtabela(i)));
    }

    abrirQrCode(veiculos) {
      const veiculosId = veiculos.map((v) => v.id),
          qrcodePageUrl = this.state.href('root.veiculo.qrcode');

      this.window.localStorage.setItem('qrcode-veiculos-params', angular.toJson({veiculosId}));
      this.window.open(qrcodePageUrl, '_blank');
    }

    cancelarAlteracaoVeiculo() {
      this.vtabela.grid.data
        .filter(x => x.id === this.beforeEdit.id)
        .forEach(x => {
          angular.merge(x, this.beforeEdit);
        });

      this.beforeEdit = null;
    }

    editarGrupoVeiculosModal(veiculosSelecionadosEditaveis) {
      return this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/veiculo/editar-grupo/editar-grupo.tpl.html',
        controller: 'EditarGrupoCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          veiculos: () => veiculosSelecionadosEditaveis
        }
      }).result
        .then(data => {
          this.alertMessage.create({
            type: 'success',
            message: data.message,
            appendTo: '.alerta-aqui'
          });
          this.replaceGruposGrid(data.edition);
        })
        .catch(data => {
          if (data && data.message) {
            this.alertMessage.create({
              type: 'error',
              message: data.message,
              appendTo: '.alerta-aqui'
            });
          }
        });
    }

    desvincularGrupoVeic(veiculosSelecionadosComGrupo) {
      let configs = {
        title: this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.title'),
        btnSecundario: this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.btnSecundario'),
        btnPrimario: this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.btnPrimario'),
        conteudo: veiculosSelecionadosComGrupo.length === 1 ?
          this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.msgConfirmacao') :
          this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.msgConfirmacaoMaisDeUm')
      };

      this.modalInfoConfirmService.confirmModal(configs).then(() => {
        this.efetivaDesvinculo(veiculosSelecionadosComGrupo);
      });
    }

    efetivaDesvinculo(veiculos) {
      let veiculosIds = veiculos.map(veiculo => veiculo.id);

      this.httpService.post(`/veiculo/v2/veiculos/batch`, {
        veiculoIds: veiculosIds,
        patch: {
          grupoId: null
        }
      })
        .success(() => {
          this.alertMessage.create({
            type: 'success',
            message: veiculosIds.length === 1 ?
              this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.efetivaDesvinculo.sucesso') :
              this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.efetivaDesvinculo.sucessoMaisDeUm'),
            appendTo: '.alerta-aqui'
          });
          this.replaceGruposGrid({
            veiculosIds,
            grupo: {
              nome: '-'
            }
          });
        })
        .error(() => {
          this.alertMessage.create({
            type: 'danger',
            message: veiculosIds.length === 1 ?
            this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.efetivaDesvinculo.erro') :
            this.translate.instant('ce.cadastro.veiculos.desvincularGrupoVeic.efetivaDesvinculo.erroMaisDeUm'),
            appendTo: '.alerta-aqui'
          });
        });
    }

    replaceGruposGrid(edition) {
      edition.veiculosIds.forEach(veiculoId => {
        let veiculoConsulta = this.veiculos.find(veic => veic.id === veiculoId);
        if (angular.isDefined(veiculoConsulta.grupo)) {
          veiculoConsulta.grupo.nome = edition.grupo.nome;
        } else {
          veiculoConsulta.grupo = {
            nome: edition.grupo.nome
          };
        }
        this.atualizaVeiculoVtabela(veiculoConsulta);
      });
      this.isFirstLoad = false;
    }

    confirmarAlteracaoHodometro(novoValorHodometro, hodometroOriginal, rowEntity) {
      this.hodometroOriginal = hodometroOriginal;
      return this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/veiculo/hodometro-confirmacao.tpl.html',
        controller: 'HodometroConfirmacaoCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          novoValorHodometro,
          placa: () => rowEntity.placa
        }
      }).result.then(() => {
        this.salvarHodometro(this.vDataToVeiculo(rowEntity));
      }, () => {
        this.cancelarAlteracaoVeiculo();
      });
    }

    confirmarAlteracaoPrefixo(rowEntity) {
      return this.modalInfoConfirmService.confirmModal({
        title: `${this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.title')}`,
        conteudo: `${this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.conteudoPt1')} ${rowEntity.placa} ${this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.conteudoPt2')} <strong>${rowEntity.prefixo}</strong>?`,
        btnSecundario: `${this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.btnSecundario')}`,
        btnPrimario: `${this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.btnPrimario')}`,
        headerColor: 'bg-light-blue',
        typeButton: 'btn-primary'
      })
      .then(() => this.salvarPrefixo(this.vDataToVeiculo(rowEntity)))
      .catch(() => this.cancelarAlteracaoVeiculo());
    }

    salvarPrefixo(veiculo) {
      this.httpService.patch(`/veiculo/v2/veiculos/${veiculo.id}`, {
        prefixo: veiculo.prefixo
      })
      .success(() => {
        this.atualizaVeiculoVtabela(veiculo);
        return this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.salvarPrefixo.sucesso'),
          appendTo: '.alerta-aqui'
        });
      })
      .error(() => {
        this.cancelarAlteracaoVeiculo();
        return this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.veiculos.alteracaoPrefixo.salvarPrefixo.erro'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    salvarHodometro(veiculo) {
      this.restangular.all('veiculo/historico-hodometro').post({
        datahora: this.moment(),
        veiculo: {id: veiculo.id},
        uo: {id: veiculo.uo.id},
        hodometroPainel: veiculo.hodometro * 1000
      }).then(() => {
        this.hodometroOriginal = '';
        this.atualizaVeiculoVtabela(veiculo);
        return this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.cadastro.veiculos.alteracaoHodometro.salvarHodometro.sucesso'),
          appendTo: '.alerta-aqui'
        });
      })
      .catch(() => {
        veiculo.hodometro = this.hodometroOriginal;
        this.cancelarAlteracaoVeiculo();
        return this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.veiculos.alteracaoHodometro.salvarHodometro.erro'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    atualizaVeiculoVtabela(novoVeiculo) {
      if (this.vtabela) {
        this.vtabela.grid.data
        .filter(row => row.id === novoVeiculo.id)
        .map(row => {
          angular.merge(row, this.getItemVtabela(novoVeiculo));
        });
      }
    }

    alertaManutencao(veiculos, removeType = null) {
      return this.modalService.open({
        animation: true,
        templateUrl: `cadastros/veiculo/alerta-manutencao/alerta-manutencao${removeType ? '-remove' : ''}.tpl.html`,
        controller: 'AlertaManutencaoCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          veiculos: () => removeType ? this.removeManutencaoEspecificaVeiculo(veiculos, removeType) : veiculos,
          permissaoManutencaoProgramada: () => this.permissaoManutencaoProgramada,
          tipoRemocao: () => removeType
        }
      })
      .result
      .then(values => this.rebuildVeiculos(values, removeType))
      .catch(erro => {
        if (erro && erro.msg) {
          this.alertMessage.create({
            type: 'error',
            message: erro.msg,
            appendTo: '.alerta-aqui'
          });
        }
      });
    }

    removeManutencaoEspecificaVeiculo(veiculos, removeType) {
      veiculos = angular.isArray(veiculos) ? veiculos : [veiculos];

      if (removeType === 'hodometro') {
        return veiculos.map(veiculoSelecionado => {
          veiculoSelecionado.manutencaoProgramada.hodometroProximoAlertaManutencao = 0;
          veiculoSelecionado.manutencaoProgramada.intervaloManutencao = 0;
          veiculoSelecionado.manutencaoProgramada.margemAlertaManutencao = 0;

          return veiculoSelecionado;
        });
      }

      return veiculos.map(veiculoSelecionado => {
        veiculoSelecionado.manutencaoProgramada.horimetroProximoAlertaManutencao = 0;
        veiculoSelecionado.manutencaoProgramada.intervaloManutencaoHorimetro = 0;
        veiculoSelecionado.manutencaoProgramada.margemAlertaManutencaoHorimetro = 0;

        return veiculoSelecionado;
      });
    }

    abrirModalDefinirSituacao(veiculos) {
      return this.modalService.open({
        animation: true,
        templateUrl: `cadastros/veiculo/situacao/situacao-veiculo.tpl.html`,
        controller: 'SituacaoVeiculoCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        resolve: {
          veiculos: () => veiculos
        }
      })
      .result
      .then((result) => {
        this.updateSituacaoVeiculos(result.situacao, result.dataRetorno, veiculos);
        this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.cadastro.veiculos.modalSituacao.sucesso'),
          appendTo: '.alerta-aqui'
        });
      })
      .catch(erro => {
        if (erro && erro.msg) {
          this.alertMessage.create({
            type: 'error',
            message: erro.msg,
            appendTo: '.alerta-aqui'
          });
        }
      });
    }

    updateSituacaoVeiculos(situacaoVeiculo, dataRetorno, veiculosAtualizados) {
      let veiculoIds = veiculosAtualizados.map(v => v.id);
      this.veiculos
        .filter(x => _.contains(veiculoIds, x.id))
        .forEach(x => {
          x.situacaoVeiculo = situacaoVeiculo;
          x.dataRetorno = dataRetorno;
          this.atualizaVeiculoVtabela(x);
          return x;
        });
    }

    rebuildVeiculos(values, remove) {
      if (values && values.length > 0) {
        values.forEach(value => {
          let itemTabela = this.vtabela.grid.data.find(x => x.id === value.veiculoId);
          if (itemTabela) {
            angular.merge(itemTabela, {
              intervaloManutencao: !isNaN(value.intervaloManutencao) ? value.intervaloManutencao : null,
              margemAlertaManutencao: value.margemAlertaManutencao || null,
              hodometroProximoAlertaManutencao: value.hodometroProximoAlertaManutencao || null,
              intervaloManutencaoHorimetro: !isNaN(value.intervaloManutencaoHorimetro) ? value.intervaloManutencaoHorimetro : null,
              margemAlertaManutencaoHorimetro: value.margemAlertaManutencaoHorimetro || null,
              horimetroProximoAlertaManutencao: value.horimetroProximoAlertaManutencao || null
            });
          }
        });
        this.alertMessage.create({
          type: 'success',
          message: `${this.sucessoPt1} ${remove ? this.removida : this.cadastrada } ${this.sucessoPt2}`,
          appendTo: '.alerta-aqui'
        });
      }
    }

    getSelecionadosComGrupo() {
      if (!this.vtabela || !this.vtabela.rowsSelected) {
        return [];
      }
      return this.vtabela.rowsSelected.filter(veic =>
        this.tipoPermissaoEnum.podeEditar(veic.tipoPermissao) &&
        veic.grupo !== '-'
      );
    }

    getEditableRowsSelected() {
      if (!this.vtabela || !this.vtabela.rowsSelected) {
        return [];
      }
      return this.vtabela.rowsSelected.filter(i => this.tipoPermissaoEnum.podeEditar(i.tipoPermissao));
    }

    getStatusi18n(status) {
      return this.mapStatusI18N[status];
    }

    getSituacao18n(status) {
      return this.mapSituacaoI18N[status];
    }
  }

  class HodometroConfirmacaoCtrl {
    constructor($uibModalInstance, novoValorHodometro, placa) {
      this.modalInstance = $uibModalInstance;
      this.hodometro = novoValorHodometro;
      this.placa = placa;
    }

    ok() {
      this.modalInstance.close();
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('cadastros.veiculo')
    .controller('VeiculoCtrl', VeiculoCtrl)
    .controller('HodometroConfirmacaoCtrl', HodometroConfirmacaoCtrl);
}());
